import * as moment from 'moment';

export enum FlexNews_Constants {
    TIMEOUT = 3000,
    /**
     * Pagination Constants
     */
    NEWS_PAGE_LIMIT = 12,
    SHORT_NEWS_PAGE_LIMIT = 6,

    /**
     * Socket Constants
     */
    PRICE_EVENT = 'price',
    DEFAULT_PAIR_TYPE = 'trade',
    COIN_PRICE_ROOM = 'coinPrice',

    /**
     * Default values
     */
    DEFAULT_PAGE_TITLE = 'Research | IXFI',

    /**
     * Numerical Constants
     */
    ONE_HUNDRED = 100,
    ONE_THOUSAND = 1000,
    ONE_MILLION = 1000000,
    ONE_BILLION = 1000000000,
    XXL_DEVICE_RESOLUTION = 1400
}

export enum OrderType {
    'Market' = 0,
    'Limit' = 1,
    'Stop-Limit' = 2
}
export enum OrderStatus {
    'Open' = 0,
    'Filled' = 1,
    'Partially filled' = 2,
    'Pending' = 3,
    'Executed' = 4,
    'Cancelled' = 5,
    'In queue' = 6,
    'Rejected' = 7,
    'Success' = 8,
    'Completed' = 9,
    'failed' = 10,
    'Fail' = 'FAIL',
    'CREATED' = 'created',
    'PENDING' = 'pending',
    'PROCESSING' = 'processing',
    'REJECTED' = 'rejected',
    'FAILED' = 'failed',
    'COMPLETED' = 'completed',
    'DECLINED' = 'declined'
}
export enum Side {
    Buy = 0,
    Sell = 1
}

export enum MarketSpotAPIDataType2Enum {
    BASE_ASSET_IMAGE = 'base_coin_icon',
    SYMBOL_NAME = 'pair_name',
    CONTRACT_TYPE = 'contractType',
    DELIVERY_DATE = 'deliveryDate',
    BASE_ASSET = 'base_coin_code',
    QUOTE_ASSET = 'quote_coin_code',
    BASE_ASSET_FULL_NAME = 'baseAssetFullName',
    QUOTE_ASSET_FULL_NAME = 'quoteAssetFullName',
    LAST_PRICE = 'last_price',
    PRICE_CHANGE = 'price_change',
    PRICE_CHANGE_PERCENT = 'price_change_percent',
    HIGH_PRICE_24H = 'high_price',
    LOW_PRICE_24H = 'low_price',
    VOLUME_24H = 'quote_volume',
    VOLUME_BASE_24H = 'baseVolume',
    TOTAL_VOLUME_24H = 'volume',
    BASE_ASSET_PRECISION = 'base_coin_precision',
    CIRCULATION_SUPPLY = 'cs',
    EVENT_TIME = 'updated_at',
    CHART_DATA = 'graph_data',
    CHART_LABEL = 'graph_label',
    FIAT_VALUE = 'FIAT_currency_value',
    IS_PRICE_UP = 'isSymbolPriceUp',
    TICK_SIZE = 'tick_size',
    FIAT_CURRENCY_VALUE = 'FIAT_currency_value',
    QUOTE_COIN_PRECISION = 'quote_coin_precision',
    BASE_COIN_PRECISION = 'base_coin_precision',
    IS_FAV = 'isFav',
    QUOTE_COIN_NAME = 'quote_coin_name',
    BASE_COIN_Name = 'base_coin_name',
    ID = '_id',
    SYMBOL = 'symbol'
}

export enum MarketSpotSocketDataTypeEnum {
    EVENT_TYPE = 'e',
    SYMBOL_NAME = 's',
    PRICE_CHANGE = 'p',
    PRICE_CHANGE_PERCENT = 'P',
    LAST_PRICE = 'c',
    HIGH_PRICE_24H = 'h',
    LOW_PRICE_24H = 'l',
    VOLUME_24H = 'q',
    EVENT_TIME = 'E',
    BASE_ASSET_VOLUME = 'v',
    CURRENCY_COIN_PRICE = 'currency_coin_price'
}

export enum Precision {
    USD = 5,
    BTC = 8,
    ETH = 4,
    INR = 2,
    EUR = 5,
    RON = 5,
    GBP = 5,
    CHF = 5
}

export const FIAT_MARKET_COINS = ['FIAT', 'USDT', 'BUSD', 'TUSD', 'USDC', 'DAI', 'FDUSD', 'VAI'];
export const ALT_MARKET_COINS = ['BNB', 'TRX', 'XRP'];

export enum orderBookSocketEnum {
    EVENT_TYPE = 'e',
    SYMBOL_NAME = 's',
    FIRST_UPDATE_ID = 'U',
    FINAL_UPDATE_ID = 'u',
    BIDS_ARRAY = 'bids', // bids for latest structure
    ASKS_ARRAY = 'sell', // asks for latest structure,
    LAST_UPDATED_ID = 'updateId',
    META_DATA = 'meta_data',
    ORDER_ROW_HEIGHT = 25
}

export const tvIntervals = {
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '120': '2h',
    '240': '4h',
    '360': '6h',
    '480': '8h',
    '720': '12h',
    D: '1d',
    '1D': '1d',
    '3D': '3d',
    W: '1w',
    '1W': '1w',
    M: '1M',
    '1M': '1M'
};

export const paginationRules: any = {
    pageNumber: 1,
    per_page_limit: 10
};

export const DEFAULT_PAIRED_CURRENCY: string = 'USDT'; //** Default currency selected ${1 USDT} === ${1 USD} */
export const AGG_TRADES_DATA_LIMIT: number = 40;

export const TRADE_PRO_ORDER_BOOK_DATA_LIMIT: number = 1000;

export enum STORAGE_KEY {
    TOKEN = 'token',
    TOKEN2FA = 'token_2fa',
    TOKEN2FA_ID = 'twofa_uuid',
    IS_LOGGED_IN = 'isLoggedIn',
    USER = 'user',
    GEE_TEST = 'geetest',
    EMAIL_LOGIN = 'emailLogin',
    SEND_TO = 'sendTo',
    EMAIL_DATA = 'emaildata',
    EMAIL = 'email',
    PHONE_DATA = 'phonedata',
    PHONE = 'phone',
    FORM_DATA = 'formData',
    CODE_COUNTY = 'code_country',
    MOBILE_COUNTRY_ID = 'mobile_country_id',
    GOOGLE_AUTH_DATA = 'google_auth_data',
    RETURN_URL = 'returnUrl',
    TOKEN_EXPIRE_TIME = 'token_expire_time',
    SELECTED_CURRENCY = 'selectedCurrency',
    SELECTED_LANGUAGE = 'selectedLanguage',
    IS_MOBILE = 'is_mobile',
    EXPIRE_TIME = 'token_expire_time',
    REFRESH_TOKEN = 'refreshToken',
    IS_DARK_MODE = 'isDarkMode',
    IS_PROFILE_COMPLETE = 'is_profile_complete',
    AUTO_THEME = 'autoTheme',
    DEFAULT_CURRENCY_CODE = 'usd',
    IS_DEPOSIT = 'isdeposit',
    IS_TRADE = 'istrade',
    LOGIN = 'login',
    MOBILE = 'Mobile',
    IS_KYC = 'is_kyc',
    FALSE = 'false',
    LOGIN_WITH_PHONE = 'loginWithPhone',
    MOBILE_COUNTRY_CODE = 'mobile_country_code',
    PHONE_CODE = 'phonecode',
    DEVICE_TOKEN = 'device_token',
    EMPTY_STRING = '',
    LOGIN_DATA = 'loginData',
    LOGIN_TYPE = 'loginType',
    AUTH_TYPE = 'authType',
    SMS = 'sms',
    GOOGLE_AUTH = 'google_auth',
    DEFAULT_LANG = 'en',
    INITGEETEST = 'initGeetest',
    BIND = 'bind',
    HUNDRED_PERCENTAGE = '100%',
    GEETEST = 'geetest',
    IS_SUBDOMAIN = 'isSubDomain',
    SMS_AUTH_DATA = 'sms_auth_data',
    SMS_DATA = 'sms_data',
    SIGNUP = 'signup',
    AFFILIATE_CODE = 'affiliate_code',
    REFERRAL_CODE = 'referral_code',
    USER_NAME = 'username',
    ID = '_id',
    MOBILE_NO = 'mobile_no',
    COOKIE_CONSENT = 'cookieConsent',
    NAME = 'name',
    FORGET_PASSWORD = 'forgot_password',
    INTERCOM_SESSION = 'intercom-session-',
    INTERCOM_ID = 'intercom-id-',
    SIGNUP_TYPE = 'SIGNUP_TYPE',
    TWO_FA = 'TWO_FA',
    DEFAULT_DATE = '1901-01-01',
    TWO_FA_UUID = 'twofa_uuid',
    DOWNLOAD_APP = 'appDownload',
    FP_VISITOR_ID = 'X-Visitor-Id',
    FP_REQUEST_ID = 'X-Request-Id',
    RESET_TOKEN = 'resetToken',
    BACKUP_CODE = 'backup_code',
    IMPLICIT_SCA_TOKEN = 'implicit_sca_token'
}

export enum API_RESPONSE_STATUS {
    //success
    success_code = 200,
    two_factor_needed = 600,
    per_page_limit = 10,
    accepted_code = 202,
    sms_auth_needed = 601,
    sms_two_factor_auth_needed = 603,

    //error visible
    limit_reached = 429,
    bad_request_code = 400,
    server_error_code = 500,

    // server
    unauthorized_code = 401,
    forbidden_code = 403,

    // error disable
    email_not_verified = 417,
    authentication_expired = 419,
    service_unavailable = 503
}

export const TIMER_DEFAULTS = {
    WAITING_TIME: 120,
    SHOW_TIME: '02:00'
};
export const TIMER_DEFAULTS_N = {
    WAITING_TIME: { 0: 0, 1: 120, 2: 300, 3: 6000 },
    SHOW_TIME: { 0: '00:00', 1: '02:00', 2: '5:00', 3: '10:00' }
};

export const STATIC_PAGE_SLUG = {
    'about-us': 'aboutus',
    'privacy-policy': 'privacy_policy',
    faq: 'faq',
    'terms-and-conditions': 'terms',
    loan: 'loans'
};

export const TRADE_TAB_MODES = {
    TAB_MODE_ADVANCED: 'advanced',
    TAB_MODE_STANDARD: 'standard',
    TAB_MODE_CANVAS: 'canvas'
};

export const BSDatePickerRange = [
    {
        value: [new Date(), new Date()],
        label: 'Today'
    },
    {
        value: [
            new Date(new Date().setDate(new Date().getDate() - 1)),
            new Date(new Date().setDate(new Date().getDate() - 1))
        ],
        label: 'Yesterday'
    },
    {
        value: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()],
        label: 'Last 7 Days'
    },
    {
        value: [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()],
        label: 'Last 1 Month'
    },
    {
        value: [new Date(new Date().setMonth(new Date().getMonth() - 3)), new Date()],
        label: 'Last 3 Months'
    },
    {
        value: [
            new Date(moment([moment().year() - 2, 0, 1]).format('YYYY-MM-DD')),
            new Date(moment().format('YYYY-MM-DD'))
        ],
        label: 'All Days'
    }
];

export const tableHeadTrade = [
    'TRADE.DATE',
    'TRADE.TABLE_COLUMN_COIN',
    'TRADE.PAIR',
    'TRADE.ORDER_TYPE',
    'TRADE.SIDE',
    'TRADE.LIMIT_PRICE',
    'TRADE.STOP_PRICE',
    'TRADE.EXECUTED',
    'TRADE.REMAINING',
    'TRADE.FEE',
    'TRADE.FILL_PRICE',
    'TRADE.STATUS'
];
export const tableHeadFundData = [
    'TRADE.TABLE_COLUMN_COIN',
    'TRADE.AVAILABLE_COIN_BALANCE',
    'TRADE.IN_ORDER',
    'TRADE.TOTAL'
];

export enum TrackingEvents {
    SIGNUP_ID = 'tw-ofd70-ofhl0',
    KYC_DONE_ID = 'tw-ofd70-ofhl1',
    TRADE_ID = 'tw-ofd70-ofhla',
    BUY_CRYPTO_START_ID = 'tw-ofd70-ofhl7',
    SUBSCRIBE_ID = 'tw-ofd70-ofhl9'
}

export type NewsSentiment = 'Positive' | 'Negative' | 'Neutral';

/**
 * All types of screen sizes
 * Used to render layout appropriately
 */
export type ScreenSize = 'mobile' | 'tab' | 'desktop';

export const PLATFORMS = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

export const KYC_CODES = {
    NO_KYC: 0, // no kyc done
    ONDATO_KYC: 1, // ondato kyc done
    VERIFF_KYC: 2, // veriff kyc done
    WALLET_KYC: 3, // wallet/binance kyc done
    ONDATO_WALLET_KYC: 4, // ondato + wallet/binance done
    VERIFF_WALLET_KYC: 5 // veriff + wallet/binance don
};

/**
 * Types of Place Values
 * Used to display data according to international place value system
 */
export type PlaceValue = 'billion' | 'million' | 'thousand' | 'hundred';

/**
 * Types of charts available
 */
export type ChartType = 'price' | 'marketCap' | 'supply' | 'tradingView';

/**
 * Types of time intervals
 * Used to filter chart data
 */
export type TimeInterval = '1D' | '7D' | '1M' | '3M' | '1Y';

export enum APP_CONSTANTS {
    TIMEOUT = 3000,
    /**
     * Pagination Constants
     */
    NEWS_PAGE_LIMIT = 24,
    DETAIL_PAGE_NEWS_LIMIT = 48,
    TABLE_PAGE_LIMIT = 21,
    SHORT_NEWS_PAGE_LIMIT = 6,
    MAX_PAGINATION_ELEMENTS = 5,

    /**
     * Socket Constants
     */

    DEFAULT_PAIR_TYPE = 'trade',
    COIN_PRICE_ROOM = 'coinPrice',
    COIN_PAIR_PRICE_ROOM = 'coinPairPrice',
    PRICE_EVENT = 'price',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    /**
     * Default values
     */
    DEFAULT_PAIRED_CURRENCY = 'USDT',
    DEFAULT_PAGE_TITLE = 'Research | ixfi',

    /**
     * Numerical Constants
     */
    ONE_HUNDRED = 100,
    ONE_THOUSAND = 1000,
    ONE_MILLION = 1000000,
    ONE_BILLION = 1000000000,
    XXL_DEVICE_RESOLUTION = 1400,

    /**
     * ITB Constants
     */
    ITB_API_KEY = 'xKxSbTrA5X40dKJswZAT18lNgVyszfQz4EfjIw52'
}

export type IxfiResearchApi =
    | 'HISTORICAL_DATA'
    | 'COIN_DETAIL'
    | 'ALL_IXFI_COINS'
    | 'WALLET_PAIRS'
    | 'MARKET_PAIRS'
    | 'COIN_INFO_JSON'
    | 'COIN_PRICE_JSON'
    | 'COIN_PAIRS';
