import { Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { APP_CONSTANTS } from 'src/app/core/constants/enum.constants';

@Component({
    selector: 'app-terms-and-conditions-modal',
    templateUrl: './terms-and-conditions-modal.component.html',
    imports: [RouterLink, TranslateModule],
    standalone: true,
    styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent {
    @Input() description!: string;
    @Input() title!: string;
    APP_CONSTANTS = APP_CONSTANTS;

    constructor(private activeModal: NgbActiveModal, private router: Router) {}

    onClose(reason: string) {
        this.activeModal.dismiss(reason);
    }
}
