import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { STORAGE_KEY } from '../../constants/enum.constants';
import { IxfiLanguageService, Language } from 'header';
import { StorageService } from '../../../shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private language: IxfiLanguageService,
        private storageService: StorageService,
        @Inject(PLATFORM_ID) private platformId,
        @Optional() @Inject(REQUEST) private httpRequest
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authReq = req;
        let header: HttpHeaders = authReq.headers;
        let apiToken = '3e43486aca8443c1a53ef864753ec7cc';
        const isServer = isPlatformServer(this.platformId);

        // checking if the request is for uploading an media via formData
        const contentTypeForm: any = JSON.parse(this.storageService.getItem('contentTypeForm') || 'false');
        if (!contentTypeForm) header = header.append('Content-Type', 'application/json');

        // required device type
        header = header.append('device-type', 'web');

        // checking if the visitor id for X-Visitor-Id
        const visitorId: any = this.storageService.getItem(STORAGE_KEY.FP_VISITOR_ID) || '';
        if (visitorId) header = header.append(STORAGE_KEY.FP_VISITOR_ID, visitorId);

        // checking if the request id for X-Request-Id
        const requestId: any = this.storageService.getItem(STORAGE_KEY.FP_REQUEST_ID) || '';
        if (requestId) header = header.append(STORAGE_KEY.FP_REQUEST_ID, requestId);

        //updating user selected lang in header
        const selectedLanguage: Language = this.language.selectedLanguage;
        if (selectedLanguage) header = header.append('language', selectedLanguage.language_code.toLowerCase() || 'en');

        // checking logged in status and adding token
        let token;
        // this.auth.isLoggedIn() // todo: need to check this line for server
        if (isServer) token = this.httpRequest?.cookies[STORAGE_KEY.TOKEN];
        else token = this.storageService.getItem(STORAGE_KEY.TOKEN) || '';
        if (token) {
            header = header.append(STORAGE_KEY.TOKEN, token);
        }

        // checking logged in status and adding refresh token
        let refreshToken: any;
        if (isServer) refreshToken = this.httpRequest?.cookies[STORAGE_KEY.REFRESH_TOKEN];
        else refreshToken = this.storageService.getItem(STORAGE_KEY.REFRESH_TOKEN);
        if (req.url.includes('refresh-token')) header = header.append('refresh-token', refreshToken);

        // checking request url adding reset-2fa
        let token2fa;
        if (isServer) token2fa = this.httpRequest?.cookies[STORAGE_KEY.TOKEN2FA];
        else token2fa = this.storageService.getItem(STORAGE_KEY.TOKEN2FA);
        if (req.url.includes('reset-2fa') && token2fa) header = header.append(STORAGE_KEY.TOKEN2FA, token2fa);

        // if (
        //     req.url.includes('coin-price/get-all-coin-price') ||
        //     req.url.includes('coin-price/get-all-active-coin-price') ||
        //     req.url.includes('coin-price/get-all-influxdb-data?id=')
        // ) {
        //     header = header.append('x-api-token', apiToken);
        // }

        if (req.url.includes(environment.BINANCE_API_FAILS_URL)) {
            header = header.delete('Content-Type');
            header = header.delete('device-type');
            header = header.delete('language');
            header = header.delete('token');
            header = header.delete('x-visitor-id');
            header = header.delete('x-request-id');
        }

        if (req.url.includes(environment.COIN_SERVICE)) {
            header = header.delete('token');
            header = header.delete('x-visitor-id');
            header = header.delete('x-request-id');
        }

        authReq = req.clone({ headers: header });
        return next.handle(authReq);
    }
}
export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
