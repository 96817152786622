import { Injectable, OnDestroy, inject } from '@angular/core';
import { ApiService } from '../../../core/http/api.service';
import { BehaviorSubject, Observable, Subscription, map, of, switchMap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WalletPair, WalletCoin, UserWallet, WalletCoinBalanceInfo } from '../models/wallet.model';
import { CoinPriceInfo } from '../models/coin-price.model';
import { CoinWalletDetail } from '../models/wallet-detail.model';
import { NetworkAddress } from '../models/network-address.model';
import { WalletHistory } from '../models/wallet-history.model';
import { WalletTransaction } from '../constants/wallet.types';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class WalletService implements OnDestroy {
    walletListData: any = {};
    walletAvailableBalance: any;
    walletAvailableBalanceUpdated: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    private _walletPairs: { [coinCode: string]: WalletPair } = {};

    private _USER_WALLET: UserWallet = {};
    private _userWalletSubject = new BehaviorSubject<UserWallet>(this._USER_WALLET);
    userWalletObs = this._userWalletSubject.asObservable();

    private _COIN_PRICE_JSON: { [coinId: string]: CoinPriceInfo } = {};
    get coinPriceInfo(): { [coinId: string]: CoinPriceInfo } {
        return this._COIN_PRICE_JSON;
    }
    private api = inject(ApiService);
    private http = inject(HttpClient);
    private router = inject(Router);

    // coin info

    showBalance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showBalance$ = this.showBalance.asObservable();

    walletDetails = new BehaviorSubject<CoinWalletDetail>({} as CoinWalletDetail);
    walletDetails$ = this.walletDetails.asObservable();
    public coinCode: any = {};

    walletCoin: WalletCoinBalanceInfo;
    coinPriceDetail = new BehaviorSubject<{ [coinId: string]: CoinPriceInfo }>({});
    coinPriceDetail$ = this.coinPriceDetail.asObservable();
    coinPriceInfoData: { [coinId: string]: CoinPriceInfo };

    subscriptions: Subscription[] = [];

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    /**
     * HTTP call to fetch User Wallet
     */
    fetchUserWallet(): Observable<UserWallet> {
        return this.http.get<UserWalletResponse>(`${environment.IXFI_API}${WALLET_API.GET_USER_WALLET}`).pipe(
            map(response => {
                if (response.status == 200) return this.mapUserWalletResponse(response.data);
                else return this._USER_WALLET;
            })
        );
    }

    /**
     * Utility Func
     * Map and create UserWallet interface from response
     * @param response response for fetchUserWallet() call
     * @returns UserWallet Object
     */
    private mapUserWalletResponse = (response: {
        wallet_info: { [coinId: string]: WalletCoin };
        total: number;
    }): UserWallet => {
        this._USER_WALLET = {};
        if (response) {
            Object.entries(response.wallet_info).forEach(entry => {
                const [key, value] = entry;
                this._USER_WALLET[key] = {
                    balance: value.balance,
                    placedBalance: value.placed_balance,
                    stakedBalance: value.staked_balance
                };
            });
            this._userWalletSubject.next(this._USER_WALLET);
        }
        return this._USER_WALLET;
    };

    /**
     * HTTP call to fetch Wallet Coin Object
     * @param payload
     * @returns
     */
    fetchCoinBalanceInWallet(payload: { coin_codes: string[] }): Observable<WalletCoinBalanceInfo> {
        return this.http
            .post<CoinWalletBalanceResponse>(`${environment.IXFI_API}${WALLET_API.POST_WALLET_DETAIL_V2}`, payload)
            .pipe(
                map(response => {
                    let coinBalanceInfoInWallet: WalletCoinBalanceInfo = <WalletCoinBalanceInfo>{};
                    if (response.status === 200) {
                        coinBalanceInfoInWallet = response.data[0];
                    }
                    return coinBalanceInfoInWallet;
                })
            );
    }

    /**
     * This fnc is called in the resolver
     * @returns Coin Price Information of all coins in Key-value format
     */
    fetchCoinPriceInfo(): Observable<{ [coinId: string]: CoinPriceInfo }> {
        // if (Object.keys(this._COIN_PRICE_JSON).length > 0) {
        //     return of(this._COIN_PRICE_JSON);
        // }
        return this.http.get<CoinPriceResponse>(`${environment.IXFI_API}api/public-api/coin-price-id`).pipe(
            map(response => {
                let coinPriceInfo: { [coinId: string]: CoinPriceInfo } = {};
                if (response.status === 200) coinPriceInfo = response.data;
                this._COIN_PRICE_JSON = coinPriceInfo;
                return coinPriceInfo;
            })
        );
    }

    /**
     * Utility fnc ~ Update and next user wallet
     * @param walletObj wallet object to be updated in userWallet
     */
    updateWallet(walletObj: { coinId: string; balance: number; placedBalance: number; stakedBalance?: number }): void {
        /**
         * User has some existing balance for the particular coin
         * Update the `balance`, `placed_balance` and the `staked_balance`
         */
        if (this._USER_WALLET[walletObj.coinId]) {
            this._USER_WALLET[walletObj.coinId].balance = walletObj.balance;
            this._USER_WALLET[walletObj.coinId].placedBalance = walletObj.placedBalance;
            this._USER_WALLET[walletObj.coinId].stakedBalance = walletObj.stakedBalance ? walletObj.stakedBalance : 0;
        } else {
            /**
             * User has no balance for this particular coin
             * Add the new `balance`, `placed_balance` and the `staked_balance` fields
             */
            this._USER_WALLET[walletObj.coinId] = {
                balance: walletObj.balance,
                placedBalance: walletObj.placedBalance,
                stakedBalance: walletObj.stakedBalance ? walletObj.stakedBalance : 0
            };
        }
        this._userWalletSubject.next(this._USER_WALLET);
    }

    /**
     * HTTP call to fetch the Wallet Pairs for the coin codes present in the payload
     */
    fetchWalletPairs(coinName: string): Observable<WalletPair> {
        const payload = { coin_codes: [coinName] };
        return this.http
            .post<WalletPairsResponse>(`${environment.IXFI_API}${WALLET_API.POST_WALLET_PAIRS}`, payload)
            .pipe(map(response => this.mapWalletPairs(response.data.coinInfo[0])));
    }

    /**
     * This fnc maps the Wallet Pairs response and formulates the `displayText`
     * in-house variable for each wallet pair
     */
    private mapWalletPairs = (response: WalletPair): WalletPair => {
        response.coin_pairs = response.coin_pairs.map(pair => ({
            ...pair,
            displayText: pair.base_coin_code + '/' + pair.quote_coin_code
        }));
        // response.swap_coin_pairs = response.swap_coin_pairs.map(pair => ({
        //     ...pair,
        //     displayText: pair.base_symbol + '/' + pair.quote_symbol
        // }));
        response.convert_coin_pairs = response.convert_coin_pairs.map(pair => ({
            ...pair,
            displayText: pair.base_coin + '/' + pair.quote_coin
        }));
        this._walletPairs[response.coin_code] = response;
        return response;
    };

    /**
     * This fnc is called to fetch the cached wallet pair for the coin_code
     * @param coinCode coin_code
     * @returns The wallet pair for that particular coin code
     */
    getCachedWalletPair(coinCode: string): WalletPair | undefined {
        let cachedWalletPair: WalletPair | undefined;
        if (this._walletPairs[coinCode]) cachedWalletPair = this._walletPairs[coinCode];
        return cachedWalletPair;
    }

    /**
     * HTTP call to fetch the Wallet detail of a coin
     * @param payload coin_code
     * @returns wallet detail of the particular coin_code
     */
    fetchCoinWalletDetail(payload: { wallet_coin_codes: string[] }): Observable<CoinWalletDetail> {
        return this.http
            .post<WalletDetailResponse>(`${environment.IXFI_API}${WALLET_API.POST_WALLET_DETAIL}`, payload)
            .pipe(
                map(response => {
                    let walletDetail: CoinWalletDetail = <CoinWalletDetail>{};
                    if (response.status === 200) {
                        walletDetail = response.data[0];
                    }
                    return walletDetail;
                })
            );
    }

    /**
     * HTTP call to fetch the network addresses supported by the coin
     * @param coinCode coin code
     * @returns Network Addresses for the particular coin
     */
    fetchNetworkAddresses(coinCode: string) {
        return this.http
            .post<NetworkAddressResponse>(`${environment.IXFI_API}${WALLET_API.FETCH_NETWORK_ADDRESSES}`, {
                coin_code: coinCode
            })
            .pipe(
                map(response => {
                    let addresses: NetworkAddress[] = [];
                    if (response.status === 200) addresses = response.data;
                    return addresses;
                })
            );
    }

    /**
     * HTTP call to fetch the network addresses supported by the coin
     * @param coinCode coin code
     * @returns Network Addresses for the particular coin
     */

    fetchNetworkAddressWithNetwork(coinCode: string) {
        return this.http
            .get<NetworkAddressResponse>(
                `${environment.IXFI_API}${WALLET_API.FETCH_NETWORK_ADDRESSES_WITH_COIN}?coin_id=${coinCode}`
            )
            .pipe(
                map(response => {
                    let addresses: NetworkAddress[] = [];
                    if (response.status === 200) addresses = response.data;
                    return addresses;
                })
            );
    }
    createNetworkAddressByCoinID(coin_id: string) {
        return this.http
            .get<NetworkAddressResponse>(
                `${environment.IXFI_API}${WALLET_API.FETCH_NETWORK_ADDRESSES_BY_COIN}?coin_id=${coin_id}`
            )
            .pipe(
                map(response => {
                    let addresses: NetworkAddress[] = [];
                    if (response.status === 200) addresses = response.data;
                    return addresses;
                })
            );
    }
    createNetworkAddressByCoinNetWorkID(coin_id: string, network_id: string) {
        return this.http
            .post<NetworkAddressResponse>(`${environment.IXFI_API}${WALLET_API.FETCH_NETWORK_ADDRESSES_BY_COIN}`, {
                coin_id,
                network_id
            })
            .pipe(
                map(response => {
                    return response.status === 200 ? response.data : [];
                })
            );
    }

    fetchExchangeFee(): Observable<number> {
        return this.http
            .get<{ status: number; message: string; data: { exchange_fee: number } }>(
                `${environment.IXFI_API}${WALLET_API.EXCHANGE_FEES}`
            )
            .pipe(map(response => response.data.exchange_fee));
    }

    fetchWalletHistory(payload: { transaction_type: 'receive' | 'send' }, page = 1): Observable<WalletHistoryResponse> {
        return this.http.post<WalletHistoryResponse>(
            `${environment.IXFI_API}${WALLET_API.GET_WALLET_HISTORY}/${page}`,
            payload
        );
    }

    fetchPendingWalletHistory(): Observable<PendingHistoryResponse> {
        return this.http.get<PendingHistoryResponse>(`${environment.IXFI_API}${WALLET_API.GET_PENDING_HISTORY}`);
    }

    getWalletHistoryCSV(filters: any) {
        return this.api.post(WALLET_API.EXPORT, filters);
    }

    sendFund(data): Observable<{ status: number; message: string }> {
        return this.http.post<{ status: number; message: string }>(
            `${environment.IXFI_API}${WALLET_API.POST_WITHDRAW_REQUEST}`,
            data
        );
    }

    /**
     * This function checks whether user has exceeded KYC withdraw limit
     * @param coinCode Coin code of the coin user is trying to withdraw
     * @param amount Amount the user is trying to withdraw
     * @returns boolean indicating whether user has exceeded withdraw limit.
     */
    validateUserWithdrawLimit(
        coinCode: string,
        amount: number
    ): Observable<{ limitReached: boolean; maxLimit: number; similarWithdraw: boolean }> {
        let params = new HttpParams();
        params = params.append('coin_code', coinCode);
        params = params.append('amount', amount);
        return this.http
            .get<UserWithdrawLimitResponse>(`${environment.IXFI_API}${WALLET_API.GET_WITHDRAW_LIMIT}`, {
                params: params
            })
            .pipe(
                map(response => {
                    return {
                        limitReached: response.data.is_withdraw_value_higher,
                        maxLimit: response.data.max_withdraw_limit,
                        similarWithdraw: response.data.is_similar_withdraw_found
                    };
                })
            );
    }

    getWalletDetailsStatic() {
        return this.api.post(WALLET_API.V2);
    }

    setWalletDetails(data) {
        this.walletListData = data;
    }
    public getWalletPairDetailsCached(): Observable<any> {
        return this.getWalletDetailsStatic().pipe(
            map(res => {
                if (res.status == 200) {
                    this.setWalletDetails(res.data);
                    return res.data;
                } else {
                    this.setWalletDetails({});
                }
                return res;
            })
        );
    }

    public getWalletPairDetail() {
        return new Promise((resolve, _reject) => {
            if (Object.keys(this.walletListData)?.length) {
                resolve(this.walletListData);
            } else {
                const walletSub = this.getWalletDetailsStatic().subscribe({
                    next: (res: any) => {
                        if (res.status == 200) {
                            this.setWalletDetails(res.data);
                            resolve(res.data);
                        } else {
                            this.setWalletDetails({});
                            resolve(null);
                        }
                    },
                    error: () => {
                        this.setWalletDetails({});
                        resolve(null);
                    }
                });
                this.subscriptions.push(walletSub);
            }
        });
    }
    public getUserHistory(start_date?, end_date?, base_coin_id?, page?, type?) {
        const obj = {
            base_coin_id,
            start_date,
            pagination: true,
            end_date,
            type
        };
        return this.api.post(`v1/user-overview-history/list/${page}`, obj);
    }

    public getSingleHistory(type, id) {
        return this.api.get(`v1/user-overview-history/${type}/get-details/${id}`);
    }

    /**
     * Find the wallet transaction based on the current page
     * Possible options are only 2.
     * Ex: /wallet/withdraw/<coin_code> (or) /wallet/deposit/<coin_code>
     * Split the URL by the `/` separator and find the 2nd indexed element in the array to find location
     */
    getWalletTransactionBasedOnCurrentPage(): WalletTransaction {
        return <WalletTransaction>this.router.url.split('/')[2];
    }

    public updateWalletBalance(coin) {
        this.getWalletBalance();
        this.getWalletDetails(coin);
    }

    //coin info merged

    getWalletDetails(coin) {
        this.coinCode = coin;
        const walletDetSub = this.fetchCoinWalletDetail({ wallet_coin_codes: [this.coinCode] }).subscribe({
            next: response => this.setWalletData(response),
            error: () => { },
            complete: () => { }
        });
        this.subscriptions.push(walletDetSub);
    }
    setWalletData(data) {
        this.walletDetails.next(data);
        this.getWalletBalance();
    }

    setWalletBalance(data) {
        this.coinPriceDetail.next(data);
    }
    getWalletBalance() {
        const walletBalSub = this.fetchCoinPriceInfo()
            .pipe(
                switchMap((coinPriceInfo: { [coinId: string]: CoinPriceInfo }) => {
                    this.coinPriceInfoData = coinPriceInfo;
                    return this.fetchCoinBalanceInWallet({ coin_codes: [this.coinCode] });
                })
            )
            .subscribe({
                next: response => {
                    this.walletCoin = response;
                    let totalBalanceInBTC;
                    const totalBalanceInUSD =
                        (response?.balance || 0) * (this.coinPriceInfo[response?.coin_id]?.coin_price || 0);
                    Object.entries(this.coinPriceInfo).forEach(entry => {
                        const [key, value] = entry;
                        if (value.coin_code.toLowerCase() === 'btc') {
                            totalBalanceInBTC = totalBalanceInUSD / this.coinPriceInfo[key].coin_price;
                        }
                    });

                    this.setWalletBalance({
                        coinInfo: this.coinPriceInfo,
                        balanceInUsd: totalBalanceInUSD,
                        balanceInBtc: totalBalanceInBTC,
                        walletCoin: this.walletCoin
                    });
                }
            });
        this.subscriptions.push(walletBalSub);
    }

    showHideBalance() {
        this.showBalance.next(!this.showBalance.value);
    }

    fetchUpcomingBalanceSummary() {
        return this.api.get('v1/upcoming-asset/get-upcoming-balance-summery');
    }

    fetchUpcomingHistoryByCoinId(assetId: string) {
        return this.api.get('v1/upcoming-asset/get-upcoming-detail-history?asset_id=' + assetId);
    }
}

export const WALLET_API = {
    // Modified
    GET_USER_WALLET: 'v1/wallet/wallets-v3',
    POST_WALLET_PAIRS: 'v1/wallet/get-wallets-pairs',
    POST_WALLET_DETAIL: 'v1/wallet/coin-wallet-info',
    POST_WALLET_DETAIL_V2: 'v1/wallet/coin-wallet-info-v2',
    FETCH_NETWORK_ADDRESSES: 'v1/wallet/receive-fund',
    FETCH_NETWORK_ADDRESSES_WITH_COIN: 'landing-page/coin-info',
    FETCH_NETWORK_ADDRESSES_BY_COIN: 'v1/wallet/coin-wallet-address',
    POST_WITHDRAW_REQUEST: 'v1/wallet/send-fund',
    GET_WALLET_HISTORY: 'v1/wallet/wallet-history',
    GET_PENDING_HISTORY: 'v1/wallet/pending-transactions',
    GET_WITHDRAW_LIMIT: 'v1/wallet/withdraw-limit-info',

    // Old
    V2: 'v1/wallet/get-wallets-v2',
    EXPORT: 'v1/wallet/wallet-history-csv',
    HISTORY_SWAP: 'v1/swap/history',
    HISTORY_TRADE: 'v1/trade/order-history/1',
    EXCHANGE_FEES: 'v1/wallet/exchange-fee',
    GET_PAIRS: 'v1/wallet/get-wallets-pairs'
};

interface UserWalletResponse {
    status: number;
    message: string;
    data: {
        wallet_info: {
            [key: string]: WalletCoin;
        };
        total: number;
    };
}

interface CoinWalletBalanceResponse {
    status: number;
    message: string;
    data: WalletCoinBalanceInfo[];
}

interface CoinPriceResponse {
    status: number;
    message: string;
    data: {
        [coinId: string]: CoinPriceInfo;
    };
}

interface WalletPairsResponse {
    status: number;
    message: string;
    data: {
        coinInfo: WalletPair[];
    };
}

interface WalletDetailResponse {
    status: number;
    message: string;
    data: CoinWalletDetail[];
}

interface NetworkAddressResponse {
    status: number;
    message: string;
    data: NetworkAddress[];
}

interface WalletHistoryResponse {
    status: number;
    message: string;
    data: {
        docs: WalletHistory[];
        totalDocs: number;
        limit: number;
        page: number;
        totalPages: number;
        pagingCounter: number;
        hasPrevPage: boolean;
        hasNextPage: boolean;
        prevPage: number | null;
        nextPage: number | null;
    };
}

interface PendingHistoryResponse {
    status: number;
    message: string;
    data: WalletHistory[];
}

interface UserWithdrawLimitResponse {
    status: number;
    message: string;
    data: {
        max_withdraw_limit: number;
        consumed_limit: number;
        total_withdraw_value: number;
        is_withdraw_value_higher: boolean;
        is_similar_withdraw_found: boolean;
    };
}
