import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from 'src/app/core';

export interface CustomReferral {
    code: string;
    created_at: string;
    updated_at: string;
    _id: string;
}

export interface CustomRefCodeResponse {
    referList: { bind_codes: CustomReferral[]; _id: string };
}

@Injectable({
    providedIn: 'root'
})
export class ReferralService {
    constructor(private api: ApiService) {}

    getReferralOverview() {
        return this.api.get(`v1/referral/get-referral-overview`);
    }

    getUserReferralList(skip, sort) {
        return this.api.get(`v1/referral/referred-users?limit=10&skip=${skip}&sort_by=${sort}`);
    }

    getAllClaims(page) {
        return this.api.post(`v1/referral/claim-history/${page}`);
    }

    collectCommission() {
        return this.api.get(`v1/referral/collect-commission`);
    }

    setCustomReferralCode(code: string) {
        return this.api.post('v1/referral/custom-refer-code', { custom_refer: code });
    }

    getCustomReferralCodes(): Observable<CustomRefCodeResponse | undefined> {
        return this.api.get('v1/referral/custom-refer-codes').pipe(
            map((res: { status: number; data: CustomRefCodeResponse }) => {
                if (res.status == 200) {
                    return res.data;
                } else {
                    return undefined;
                }
            })
        );
    }

    getRedirectURLforCustomReferral(code: string) {
        return this.api.get('auth/refer-redirect?referral_code=' + code).pipe(
            map(res => {
                if (res.status == 200) {
                    return res.data;
                } else {
                    return {};
                }
            })
        );
    }

    getBindingCode(code: string) {
        return this.api.get('auth/binding-refer-redirect?binding_code=' + code).pipe(
            map(res => {
                return res.status == 200 ? res.data.refer_code : undefined;
            })
        );
    }

    validateRefCode(code: string) {
        return this.api.get('auth/check-referral-code?referral_code=' + code).pipe(
            map(res => {
                return res?.status == 200 && res?.data ? res.data : { can_proceed: false };
            })
        );
    }
}
