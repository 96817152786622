import { Inject, Injectable, OnDestroy, Optional, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, tap, map, Subscription } from 'rxjs';
import { API_RESPONSE_STATUS, STORAGE_KEY } from '../constants/enum.constants';
import { ApiService } from '../http/api.service';
import { isMobileModule } from '../../core/components/shell-mobile/mobile-js/mobile.js';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    ConfirmationModalComponent,
    POP_UP_TYPE
} from '../../standalone/components/confirmation-modal/confirmation-modal.component';
import { StorageService } from '../../shared/services/storage.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
    authGuardPages = [
        'profile',
        'reports',
        'wallet-old',
        'wallet',
        'history',
        'dashboard',
        'home',
        'profile-new',
        'auth/ixfi-pin'
    ];

    private refreshTokenTimeout;
    loginStatus!: BehaviorSubject<boolean>;
    public promoData;
    private modalService = inject(NgbModal);
    private storageService = inject(StorageService);
    private apiService = inject(ApiService);
    public showLayoutHeader = new BehaviorSubject(false);
    subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: object,
        @Optional() @Inject(REQUEST) private httpRequest
    ) {
        this.loginStatus = new BehaviorSubject<boolean>(this.isLoggedIn());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    // check user status of duplication
    checkUserStatus(data: object = {}): Observable<any> {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.PRE_SIGNUP_VERIFY, data);
    }
    // check user status of duplication

    // login api call changes
    login(type: string, data: object = {}): Observable<any> {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.LOGIN[type], data);
    }
    loginV2(data: object = {}): Observable<any> {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.LOGIN_V2, data);
    }
    sendPreLoginOTPSecurityV2(data) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.SEND_PRE_LOGIN_OTP_V2, data);
    }
    ipVerification(data: any) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.VERIFY_IP, data);
    }
    sendAuthSMS(authData: any) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.SEND_AUTH_SMS, authData);
    }
    // login api call changes

    //signup
    signup(data: object = {}): Observable<any> {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.SIGNUP, data);
    }

    verifySignup(type: string, data: object = {}): Observable<any> {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.SIGNUP_VERIFY[type], data);
    }
    //signup

    // forget Password
    forgotPassword(data: object = {}): Observable<any> {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.FORGET_PASSWORD, data);
    }
    // forget Password

    // reset token
    resetPassword(new_password: any, forgot_password_token: any) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.RESET_PASSWORD, { new_password, forgot_password_token });
    }
    resetPasswordV2(data: any) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.RESET_PASSWORD, data);
    }

    isResetTokenValid(token: any) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.CHECK_RESET_TOKEN, { token });
    }

    logout() {
        if (this.storageService.getItem(STORAGE_KEY.RETURN_URL)) {
            this.storageService.removeItem(STORAGE_KEY.RETURN_URL, '/', environment.DOMAIN, true);
            this.storageService.removeItem(STORAGE_KEY.IS_SUBDOMAIN, '/', environment.DOMAIN, true);
        }
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.LOGOUT).pipe(
            tap(res => {
                if (
                    [API_RESPONSE_STATUS.success_code, API_RESPONSE_STATUS.authentication_expired].includes(res.status)
                ) {
                    this.clearAuthData();
                    if (this.authGuardPages.includes(this.router.url.split('/')[1])) this.router.navigate(['/']);
                }
                return res;
            })
        );
    }

    // *** Clear auth Data
    clearAuthData(refreshTokenExpired?: boolean, showPopup?: boolean) {
        // clear local data
        this.clearData();
        // check if it's a webview route for app
        const isMobile = JSON.parse(this.storageService.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
        if (isMobile) {
            isMobileModule.isSessionExpire('session-expire');
            this.storageService.removeItem(STORAGE_KEY.IS_MOBILE);
        } else {
            if (refreshTokenExpired) {
                this.storageService.setItem(
                    STORAGE_KEY.RETURN_URL,
                    this.router.url,
                    Date.now() + 60 * 1000 * 10,
                    '/',
                    environment.DOMAIN,
                    true
                );
            }

            if (!refreshTokenExpired && showPopup) {
                this.modalService.dismissAll();
                this.triggerSessionOut(true);
            }
        }
    }

    clearDataAfterInvalidCaptcha() {
        this.clearData();
        const path = this.router.url;
        if (path == `/${API_RESPONSE_STATUS_AUTH.LOGIN.email}`) {
            window.location.reload();
        } else this.router.navigate([API_RESPONSE_STATUS_AUTH.LOGIN.email]);
    }

    clearData() {
        this.storageService.setItem(STORAGE_KEY.IS_LOGGED_IN, JSON.stringify(false));
        this.storageService.removeItem(STORAGE_KEY.IS_LOGGED_IN, '/', environment.DOMAIN, true);
        this.storageService.removeItem(STORAGE_KEY.TOKEN, '/', environment.DOMAIN, true);
        this.storageService.removeItem(STORAGE_KEY.TOKEN_EXPIRE_TIME, '/', environment.DOMAIN, true);
        this.storageService.removeItem(STORAGE_KEY.REFRESH_TOKEN, '/', environment.DOMAIN, true);
        this.storageService.removeItem(STORAGE_KEY.TOKEN2FA, '/', environment.DOMAIN, true);

        this.storageService.removeItem(STORAGE_KEY.TOKEN);
        this.storageService.removeItem(STORAGE_KEY.USER);
        this.storageService.removeItem(STORAGE_KEY.EMAIL);
        this.storageService.removeItem(STORAGE_KEY.EMAIL_DATA);
        this.storageService.removeItem(STORAGE_KEY.EMAIL_LOGIN);
        this.storageService.removeItem(STORAGE_KEY.PHONE_DATA);
        this.storageService.removeItem(STORAGE_KEY.GEE_TEST);
        this.storageService.removeItem(STORAGE_KEY.TOKEN_EXPIRE_TIME);
        this.storageService.removeItem(STORAGE_KEY.EXPIRE_TIME);
        this.storageService.removeItem(STORAGE_KEY.REFRESH_TOKEN);
        this.storageService.removeItem(STORAGE_KEY.FP_VISITOR_ID, '/', environment.DOMAIN, true);
        this.storageService.removeItem(STORAGE_KEY.FP_REQUEST_ID, '/', environment.DOMAIN, true);
        this.storageService.removeItem(STORAGE_KEY.IMPLICIT_SCA_TOKEN, '/');

        this.loginStatus.next(false);
    }

    public startRefreshTokenTimer() {
        let exp = this.storageService.getItem(STORAGE_KEY.EXPIRE_TIME) || '';
        // set a timeout to refresh the token a minute before it expires
        if (exp.length && isPlatformBrowser(this.platformId)) {
            const expires = new Date(parseInt(exp) * 1000);
            const timeout = expires.getTime() - Date.now() - 60 * 1000;

            this.refreshTokenTimeout = setTimeout(() => {
                const refreshSub = this.refreshToken().subscribe(data => {
                    if (data.status == API_RESPONSE_STATUS.success_code) this.setRefreshToken(data);
                    else {
                        // clear timeout after refresh token expiration
                        clearTimeout(this.refreshTokenTimeout);

                        // show pop up only when it's not a webview
                        let isMobile = this.storageService.getItem(STORAGE_KEY.IS_MOBILE) || 'false';
                        if (!JSON.parse(isMobile)) {
                            this.clearAuthData(true, false);
                            this.triggerSessionOut();
                        }
                    }
                });
                this.subscriptions.push(refreshSub);
            }, timeout);
        }
    }

    setRefreshToken(data) {
        this.storageService.setItem(
            STORAGE_KEY.TOKEN,
            data?.token,
            new Date(data?.token_expire_time * 1000),
            '/',
            environment.DOMAIN,
            true
        );
        this.storageService.setItem(
            STORAGE_KEY.EXPIRE_TIME,
            data?.token_expire_time,
            undefined,
            '/',
            environment.DOMAIN,
            true
        );
        this.startRefreshTokenTimer();
    }

    triggerSessionOut(isSessionTerminated?: boolean) {
        let isMobile = this.storageService.getItem(STORAGE_KEY.IS_MOBILE) || 'false';
        if (!JSON.parse(isMobile))
            this.openModel(isSessionTerminated ? POP_UP_TYPE.LOGGED_OUT : POP_UP_TYPE.SESSION_OUT, 'md');
    }

    private refreshToken() {
        return this.apiService.get(API_RESPONSE_STATUS_AUTH.REFRESH_TOKEN);
    }

    getRelease(params) {
        return this.apiService.get(API_RESPONSE_STATUS_AUTH.RELEASE_INFO, params);
    }

    isLoggedIn(): boolean {
        /**
         * Returns true if the user is Logged In and Platform is server
         *
         */
        if (this.httpRequest?.cookies && isPlatformServer(this.platformId)) {
            const isLoggedIn = this.httpRequest?.cookies[STORAGE_KEY.IS_LOGGED_IN] === 'true';
            return isLoggedIn;
        }

        let status = false;
        let isMobile = this.storageService.getItem(STORAGE_KEY.IS_MOBILE) || 'false';
        let token = this.storageService.getItem(STORAGE_KEY.TOKEN) || '';
        let expiry: any = this.storageService.getItem(STORAGE_KEY.EXPIRE_TIME) || '';
        if (token && (JSON.parse(isMobile) || new Date(expiry * 1000) >= new Date())) {
            status = true;
        }
        return status;
    }

    getPromotionalOffer() {
        return this.apiService.get(API_RESPONSE_STATUS_AUTH.PROMO_OFFER);
    }

    // ================ get Question list backup code not generated==============================//
    getUserQuestionList() {
        return this.apiService.get(API_RESPONSE_STATUS_AUTH.SECURITY_QUESTION);
    }
    // ================Submit User Answer List==============================//

    remove2faRequest(captcha_details: any) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.REMOVE_2FA, {
            captcha_details,
            operation: 'reset_2fa',
            device_type: 'web'
        });
    }

    validateSubscription(code: string) {
        const verifySub = this.apiService
            .post('auth/verify-subscription-newsletter', { subscribe_token: code })
            .subscribe(res => {
                if (res.status == 200) {
                    this.openModel(POP_UP_TYPE.SUCCESS_NEWSLETTER, 'md');
                } else {
                    this.router.navigate(['/']);
                }
            });
        this.subscriptions.push(verifySub);
    }

    openModel(type, size) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            size: size,
            centered: true,
            backdrop: true
        });
        modalRef.componentInstance.data = {
            modalRef,
            data: {},
            type: type
        };
        modalRef.result
            .then(
                result => {
                    if (type === POP_UP_TYPE.BACKUP_CODE_LOGIN_PART_A && result?.value) {
                        this.openModel(POP_UP_TYPE.BACKUP_CODE_LOGIN_PART_B, 'md');
                    } else if (type === POP_UP_TYPE.BACKUP_CODE_LOGIN_PART_B && result?.value) {
                        this.onRemoveConfirmation();
                    } else if (result?.value) {
                        // on resolve
                        this.router.navigate([API_RESPONSE_STATUS_AUTH.LOGIN.email]);
                    }
                },
                _reason => {
                    if (
                        this.authGuardPages.includes(this.router.url.split('/')[1]) &&
                        [POP_UP_TYPE.LOGGED_OUT, POP_UP_TYPE.SESSION_OUT].includes(type)
                    ) {
                        this.router.navigate(['/']);
                        this.storageService.removeItem(STORAGE_KEY.RETURN_URL, '/', environment.DOMAIN, true);
                    }
                }
            )
            .catch(err => console.log(err));
    }

    resetTwoFactor() {
        return this.apiService.get(API_RESPONSE_STATUS_AUTH.RESET);
    }
    onRemoveConfirmation() {
        const resetSub = this.resetTwoFactor().subscribe(({ status, message }) => {
            if (status == 200) {
                const modalRef = this.modalService.open(ConfirmationModalComponent, {
                    size: 'md',
                    centered: true,
                    backdrop: true
                });
                modalRef.componentInstance.data = {
                    modalRef,
                    data: {},
                    type: POP_UP_TYPE.RESET_SUCCESS
                };
                modalRef.result
                    .then(result => {
                        if (result) this.clearDataAfterInvalidCaptcha();
                    })
                    .catch(err => console.log(err));
            }
        });
        this.subscriptions.push(resetSub);
    }

    complianceStatus() {
        return this.apiService.get(API_RESPONSE_STATUS_AUTH.COMPLIANCE_STATUS);
    }
    complianceSubmit(data) {
        return this.apiService.post(API_RESPONSE_STATUS_AUTH.COMPLIANCE_SUBMIT, data);
    }
}

const API_RESPONSE_STATUS_AUTH = {
    REMOVE_2FA: 'auth/reset-2fa',
    SECURITY_QUESTION: 'auth/security-question',
    PROMO_OFFER: 'promotional_campaigns/get-active-promotional-campaigs?is_mobile=false',
    RELEASE_INFO: 'misc/release-info',
    REFRESH_TOKEN: 'auth/refresh-token',

    CHECK_RESET_TOKEN: 'auth/check-reset-token',
    LOGOUT: 'v1/users/logout',
    RESET_PASSWORD: 'auth/reset-password',
    PRE_SIGNUP_VERIFY: 'auth/pre-signup-verify',
    FORGET_PASSWORD: 'auth/forgot-password',

    LOGIN: {
        phone: 'auth/login-with-phone',
        email: 'auth/login'
    },
    LOGIN_V2: 'auth/login-v2',
    VERIFY_IP: 'auth/verify-ip',
    SEND_AUTH_SMS: 'auth/send-auth-sms',

    SIGNUP: 'auth/signup',
    SIGNUP_VERIFY: {
        phone: 'auth/signup-phone-verification-code',
        email: 'auth/signup-email-verification-code'
    },
    RESET: 'v1/users/reset-2fa',
    COMPLIANCE_STATUS: 'v1/complience/status',
    COMPLIANCE_SUBMIT: 'v1/complience/submit',
    SEND_PRE_LOGIN_OTP_V2: 'auth/send-pre-login-otp-security'
};
