// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    /**
     * Unique Platform Identifier
     */
    PLATFORM: 'EXCHANGE',

    /**
     * Backend APIs
     */
    SOCKET_URL: 'https://api.zen-ex.com/',
    IXFI_WS_API: 'wss://api.zen-ex.com/',
    IXFI_API: 'https://api.zen-ex.com/api/front/',
    IXFI_STAKING_API: 'https://stacking.ixfidev.com/api/v1',

    COIN_INFO_API: 'https://coin-info.api.zen-ex.com/api/front/',
    FEE_SERVICE: 'https://api.fee-service.zen-ex.com/api/front/',
    COIN_SERVICE: 'https://coin-price.service.zen-ex.com/api/front/',
    IOS_APP_LINK: 'https://apps.apple.com/ro/app/ixfi/id1614823165',
    ANDROID_APP_LINK: 'https://play.google.com/store/apps/details?id=com.ixfi',
    NFT_WEB_URL: 'https://nft.zen-ex.com/',
    CARD_SERVICE: 'https://cards-middleware.zen-ex.com/api/front/',

    /**
     * Application Related URLs
     */
    CDN_URL: 'https://dfwt0d2sx0pdr.cloudfront.net/',
    ICON_URL: 'https://cdn.ixfi.com/coin-central/',
    CDN_Local: 'assets/images/',
    LANGUAGE_URL: 'assets/i18n/',
    DOMAIN: 'zen-ex.com',
    RELEASE_VERSION: '0306b18b-f520-4d40-9d53-9ac524f27459',
    SOCKET_ROOM_ID: '5e82bcf0aa89b510e89925e0-5e82bcf0aa89b510e89925df',

    /**
     * Web URLs
     */
    IXFI_STAKING: 'https://staking-earn.zen-ex.com/',
    IXFI_EXCHANGE: 'https://staging.zen-ex.com/',
    IXFI_REWARDS: 'https://points.zen-ex.com/',
    IXFI_RESEARCH: 'https://research-staging.zen-ex.com/',
    IXFI_STATUS: 'https://status.zen-ex.com/',
    IXFI_CARDS: 'https://cards-staging.zen-ex.com/',
    IXFI_FARMING: 'https://farming-staging.zen-ex.com/',
    DEEP_LINK_APP: 'https://linktest.zen-ex.com/',
    IXFI_ABOUT: 'https://go.zen-ex.com/',
    IXFI_BLOG: 'https://blog.zen-ex.com/',
    BASE_APP_URL: 'https://www.ixfi.com',
    IXFI_TOKEN: 'https://token.staging.zen-ex.com/',
    /*
    Trade APIs
    */
    IXFI_TRADE_API_URL: 'https://api.zen-ex.com/api/front/',
    IXFI_TRADE_SOCKET_URL: 'https://trade-events.zen-ex.com',

    /**
     * Third Party Affiliates
     * [Block 1]: Binance API URLs
     * [Block 2]: Hub-spot Config
     * [Block 3]: Firebase Config
     * [Block 4]: Intercom Id
     */
    BINANCE_API_URL: 'https://api.binance.com/api/',
    BINANCE_SPOT_WS_URL: 'wss://stream.binance.com:9443/',
    BINANCE_FALL_BACK_WS_URL: 'wss://binance-test.zen-ex.com/',
    BINANCE_FALL_BACK_API_URL: 'https://binance-api.zen-ex.com/api/',
    BINANCE_API_FAILS_URL: 'https://api.binance.com/api/v3/ping',
    BINANCE_FUTURES_USD_M_WS_URL: 'wss://fstream.binance.com/', // Futures USD-M
    BINANCE_FUTURES_COIN_M_WS_URL: 'wss://dstream.binance.com/', // Futures Coin-M

    /**
     * Third Party Affiliates
     * [Block 1]: IXFI API URLs
     */
    IXFI_API_URL: 'https://binance-api.zen-ex.com/api/',
    IXFI_SPOT_WS_URL: 'wss://stream.binance.com:9443/',
    IXFI_FUTURES_USD_M_WS_URL: 'wss://fstream.binance.com/', // Futures USD-M
    IXFI_FUTURES_COIN_M_WS_URL: 'wss://dstream.binance.com/', // Futures Coin-M

    HUB_SPOT_PORTAL_ID: '7664764',
    HUB_SPOT_CONTACT_US_FORM_ID: '778a19d4-8f00-4b0a-bc91-98c8f06f05f8',
    HUB_SPOT_OPEN_TICKET_FORM_ID: '3c40f430-8b45-4bca-9634-d1a2cc670438',

    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyDc6TyyqKkidZAkLWMTN4mOHVvbHxMML9c',
        authDomain: 'ixfi-staging.firebaseapp.com',
        projectId: 'ixfi-staging',
        storageBucket: 'ixfi-staging.appspot.com',
        messagingSenderId: '513732162238',
        appId: '1:513732162238:web:7c43ec0727e970b53e24ef',
        measurementId: 'G-GNM3PKJ614'
    },

    INTERCOM_APP_ID: 'hll0wikw',
    // geetest
    GEETEST_V4_APP_ID: {
        LOGIN: '01449a72be0f492171cb7be095eec475',
        SIGNUP: '01449a72be0f492171cb7be095eec475',
        FORGET_PASSWORD: '01449a72be0f492171cb7be095eec475',
        OTHER: '01449a72be0f492171cb7be095eec475'
    },
    hmr: false,

    // fingerprint js
    FINGER_PRINT_DEVICE_ID: '',

    // Script URLs
    GT4_JS: 'assets/js/gt4.js',
    SOURCE_BUSTER_JS: 'https://dfwt0d2sx0pdr.cloudfront.net/js/sourcebuster.min.js',
    FINGERPRINT_JS: 'https://d3hu9bnnotvinj.cloudfront.net/Xldep3xeFrEsaVe/7RLtefOy0Wr7Cha?apiKey=wcFdgGvismZcMDK8MeWU',
    FINGERPRINT_JS_END_POINT: 'https://d3hu9bnnotvinj.cloudfront.net/Xldep3xeFrEsaVe/Jo8oPhowud2The0?region=us'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
