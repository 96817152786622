import { Inject, Injectable, OnDestroy, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { AuthenticationService } from '../core/authentication';
import { UserService } from '../core/services/user.service';
import { STORAGE_KEY } from '../core/constants/enum.constants';
import { Router } from '@angular/router';
import { StorageService } from '../shared/services/storage.service';
import { IxfiLanguageService, IxfiCurrencyService } from 'header';
import { User } from '../shared/models/user.model';
import {
    ConfirmationModalComponent,
    POP_UP_TYPE
} from '../standalone/components/confirmation-modal/confirmation-modal.component';
import { Observable, Subscription } from 'rxjs';
import { IntercomService } from '../shared/services/intercom.service';
import { environment } from '../../environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../core';
import { COMPLIANCE_TYPE } from '../standalone/components/compliance/compliance.component';

import { TrustedComponent } from '../standalone/components/trusted/trusted.component';
import { isPlatformBrowser } from '@angular/common';
import { TermsAndConditionsService } from '../core/services/terms-and-conditions.service';

export const COMPLIANCE_STATUS = {
    form_1: COMPLIANCE_TYPE.TASK_UBO,
    form_2: COMPLIANCE_TYPE.TASK_CHECK_PEP,
    form_3: COMPLIANCE_TYPE.TASK_PEP_DONE
};

@Injectable({
    providedIn: 'root'
})
export class AuthModuleService implements OnInit, OnDestroy {
    canExitFromAuth: boolean;
    private modalService;
    private userService;
    private authService;
    private storageService;
    private currencyService;
    private languageService;
    private intercomService;
    private apiService;
    private tncService;
    authData: {
        isVerified: boolean;
        implicit_sca_token: string;
        refreshToken: string;
        token: string;
        token_expire_time: number;
        user: User;
        twofa_reseted: boolean;
        is_backup_login: boolean;
    };
    subscriptions: Subscription[] = [];
    modalRef!: NgbModalRef;
    constructor(private router: Router, @Inject(PLATFORM_ID) platformId: object) {
        if (isPlatformBrowser(platformId)) {
            this.modalService = inject(NgbModal);
            this.userService = inject(UserService);
            this.authService = inject(AuthenticationService);
            this.storageService = inject(StorageService);
            this.currencyService = inject(IxfiCurrencyService);
            this.languageService = inject(IxfiLanguageService);
            this.intercomService = inject(IntercomService);
            this.apiService = inject(ApiService);
            this.modalService.dismissAll();
            this.tncService = inject(TermsAndConditionsService);
        }
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public afterSuccessfulLogin(data: {
        isVerified: boolean;
        implicit_sca_token: string;
        refreshToken: string;
        token: string;
        token_expire_time: number;
        user: User;
        twofa_reseted: boolean;
    }) {
        this.setUsersData(data);

        /**
         * Update the user preference currency and language
         */
        this.currencyService.updateCurrencyById(data.user.default_currency_data._id);
        this.languageService.updateLanguageById(data.user.default_language_data._id);

        const returnUrl = this.storageService.getItem(STORAGE_KEY.RETURN_URL) || STORAGE_KEY.EMPTY_STRING;
        const isSubDomain = JSON.parse(this.storageService.getItem(STORAGE_KEY.IS_SUBDOMAIN) || STORAGE_KEY.FALSE);
        const isKyc = this.storageService.getItem(STORAGE_KEY.IS_KYC) ?? STORAGE_KEY.FALSE;

        if (isSubDomain) {
            this.storageService.removeItem(STORAGE_KEY.IS_SUBDOMAIN, '/', environment.DOMAIN, true);
            this.storageService.removeItem(STORAGE_KEY.RETURN_URL, '/', environment.DOMAIN, true);
            window.location.replace(decodeURI(returnUrl));
        } else this.ifNotSubDomain(isKyc, returnUrl, data);

        this.storageService.removeItem(STORAGE_KEY.RETURN_URL, '/', environment.DOMAIN, true);

        this.clearLoginTempData();
    }

    private ifNotSubDomain(isKyc, returnUrl, data) {
        if (JSON.parse(isKyc)) {
            if (!data.user.is_kyc_started) {
                this.router.navigate(['/profile/kyc-verification']);
            } else returnUrl ? this.router.navigateByUrl(returnUrl) : this.router.navigate(['/landing']);
        } else if (this.authData?.twofa_reseted) {
            this.authService.openModel(POP_UP_TYPE.DISABLE_AUTH_SUCCESS, 'sm');
            this.router.navigate(['/profile/account-security/authenticator'], {
                state: { status: false }
            });
        } else if (this.authData?.is_backup_login) {
            this.authService.openModel(POP_UP_TYPE.BACKUP_CODE_LOGIN_PART_A, 'md');
            this.router.navigate(['/profile/account-security/authenticator'], {
                state: { status: true }
            });
        } else returnUrl ? this.router.navigateByUrl(returnUrl) : this.router.navigate(['/landing']);
    }

    private clearLoginTempData() {
        [
            STORAGE_KEY.GOOGLE_AUTH_DATA,
            STORAGE_KEY.LOGIN_TYPE,
            STORAGE_KEY.RETURN_URL,
            STORAGE_KEY.SEND_TO,
            STORAGE_KEY.LOGIN_DATA,
            STORAGE_KEY.IS_KYC,
            STORAGE_KEY.SMS_AUTH_DATA,
            STORAGE_KEY.IS_SUBDOMAIN
        ].forEach(e => this.storageService.removeItem(e));
    }

    public afterSuccessfulSignup(response) {
        this.setUsersData(response.data);
        this.router.navigateByUrl('/home/welcome');
    }

    public setUsersData(data, type?) {
        this.authData = data;
        if (data.implicit_sca_token) {
            this.storageService.setItem(STORAGE_KEY.IMPLICIT_SCA_TOKEN, data?.implicit_sca_token);
        }
        this.languageService.updateLanguageByCode(data?.user?.default_language_data?.language_code || 'EN'); // default fallback added for lang

        if (data?.user?.default_currency_data?._id)
            this.currencyService.updateCurrencyById(data?.user?.default_currency_data?._id);
        else this.currencyService.updateCurrencyBySymbol('usd');

        // this.languageService.updateLanguageById(data.user.default_language_data._id);
        // set login status and token in cookie
        [
            {
                n: STORAGE_KEY.IS_LOGGED_IN,
                r: JSON.stringify(true),
                t: undefined,
                p: '/',
                d: environment.DOMAIN,
                s: true
            },
            {
                n: STORAGE_KEY.TOKEN,
                r: data?.token,
                t: new Date(data?.token_expire_time * 1000),
                p: '/',
                d: environment.DOMAIN,
                s: true
            },
            {
                n: STORAGE_KEY.TOKEN_EXPIRE_TIME,
                r: data.token_expire_time,
                t: undefined,
                p: '/',
                d: environment.DOMAIN,
                s: true
            },
            {
                n: STORAGE_KEY.REFRESH_TOKEN,
                r: data.refreshToken,
                t: undefined,
                p: '/',
                d: environment.DOMAIN,
                s: true
            }
        ].forEach(e => this.storageService.setItem(e.n, e.r, e.t, e.p, e.d, e.s));

        // store user's data in service
        this.userService.setUserBasicData(data?.user);

        // shut down intercom chat
        this.intercomService.shutDownChat();

        // emit login status
        this.authService.loginStatus.next(true);

        // schedule the refresh token call
        this.authService.startRefreshTokenTimer();

        //user ping
        const sub = this.userService.ping().subscribe(res => {
            if (res.status == 200) {
                this.nextRoute(type, data.user);
            }
        });
        this.subscriptions.push(sub);

        const profileSub = this.userService.getProfileStatus().subscribe();
        this.subscriptions.push(profileSub);

        // if (!data?.user.is_kyc_verified) this.openWalletKYC();
    }

    checkExitCondition(isProcessEnded: boolean): boolean | Promise<boolean> | Observable<boolean> {
        if (this.canExitFromAuth || isProcessEnded) {
            this.canExitFromAuth = false;
            return true;
        } else {
            if (this.modalRef) this.modalRef.dismiss(-1);
            this.modalRef = this.modalService.open(ConfirmationModalComponent, {
                size: 'sm',
                centered: true,
                backdrop: true
            });
            this.modalRef.componentInstance.data = {
                modalRef: this.modalRef,
                data: {},
                type: POP_UP_TYPE.YES_NO
            };
            return this.modalRef.result
                .then(
                    result => {
                        // on resolve
                        if (result?.value) return true;
                        return false;
                    },
                    _reason => {
                        return false;
                    }
                )
                .catch(err => {
                    return false;
                });
        }
    }

    public mpinAPI(urlData, mpin) {
        const url = 'v1/sca-flow/mpin/' + urlData;
        return this.apiService.post(url, mpin);
    }

    /**
     * compiles the next route &
     * should be called only after ixfi-pin and ubo
     * @param type can be login or signup
     * @param userData users basic details
     */
    public nextRoute(type, userData) {
        const returnUrl = this.storageService.getItem(STORAGE_KEY.RETURN_URL) || STORAGE_KEY.EMPTY_STRING;
        const isSubDomain = JSON.parse(this.storageService.getItem(STORAGE_KEY.IS_SUBDOMAIN) || STORAGE_KEY.FALSE);
        // if (returnUrl.includes(environment.IXFI_FARMING)) {
        //     this.redirectToSubDomain(returnUrl);
        // }
        if (type === 'signup') {
            this.router.navigateByUrl('/home/welcome');
        } else {
            if (!userData.term_conditions) {
                this.router.navigate(['/home/welcome'], {
                    state: { complianceRequired: false, userData: userData }
                });
            } else if (!userData.is_mpin_set) {
                this.router.navigate(['/auth/ixfi-pin']);
            } else if (this.authData?.is_backup_login) {
                this.authService.openModel(POP_UP_TYPE.BACKUP_CODE_LOGIN_PART_A, 'md');
                this.router.navigate(['/profile/account-security/authenticator'], {
                    state: { status: true }
                });
            } else if (!userData.is_trusted_device)
                this.router.navigate(['/home/welcome'], {
                    state: { complianceRequired: false, userData: userData }
                });
            else {
                const isKyc = this.storageService.getItem(STORAGE_KEY.IS_KYC) ?? STORAGE_KEY.FALSE;

                if (isSubDomain) {
                    this.redirectToSubDomain(returnUrl);
                } else this.ifNotSubDomain(isKyc, returnUrl, { user: userData });
            }

            this.clearLoginTempData();
        }
    }

    redirectToSubDomain(returnUrl) {
        this.storageService.removeItem(STORAGE_KEY.IS_SUBDOMAIN, '/', environment.DOMAIN, true);
        this.storageService.removeItem(STORAGE_KEY.RETURN_URL, '/', environment.DOMAIN, true);
        window.location.replace(decodeURI(returnUrl));
    }

    checkComplianceStatus(userData) {
        const sub = this.authService.complianceStatus().subscribe(({ data, status, message }) => {
            if (status === 200) {
                if (data.status !== 'done')
                    this.router.navigate(['/home/welcome'], {
                        state: { compliance: true, isComplianceFetched: true, userData }
                    });
                else {
                    this.nextRoute('', userData);
                }
            }
        });
        this.subscriptions.push(sub);
    }

    openTrusted() {
        const modalRef = this.modalService.open(TrustedComponent, {
            centered: true,
            scrollable: true,
            backdrop: 'static',
            keyboard: false
        });
        modalRef.componentInstance.data = modalRef;

        modalRef.componentInstance.isTrusted = true;
        modalRef.result
            .then(result => {
                if (result.status) this.router.navigate(['/profile/account-security/trusted-device']);
                else this.router.navigate(['/landing']);
            })
            .catch(err => console.log(err));
    }

    openWalletKYC() {
        this.modalService.dismissAll(); // temp
        const modalRef = this.modalService.open(TrustedComponent, {
            centered: true,
            scrollable: true,
            backdrop: 'static',
            keyboard: false
        });
        modalRef.componentInstance.data = modalRef;

        modalRef.componentInstance.isTrusted = false;

        modalRef.result
            .then(result => {
                if (result.status) this.router.navigate(['profile/due-diligence/kyc']);
                else this.router.navigate(['/landing']);
            })
            .catch(err => console.log(err));
    }
}
