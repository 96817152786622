<div class="modal-header border-0">
    <h5 class="modal-title">{{ title }}</h5>
</div>
<div class="modal-body terms-body">
    <div class="modal-text-scroll" id="tnCBody" [innerHTML]="description"></div>
</div>
<div class="modal-footer border-0 d-flex justify-content-center">
    <button type="button" class="btn-default btn-md purple-gradient" (click)="onClose(APP_CONSTANTS.ACCEPTED)">
        {{ 'TERMS_CONDITIONS_POPUP.TERMS_ACCEPT_BUTTON_TEXT' | translate }}
    </button>
    <button type="button" class="btn-twice-outline btn-twice-outline-lg ms-2" (click)="onClose(APP_CONSTANTS.DECLINED)">
        {{ 'TERMS_CONDITIONS_POPUP.TERMS_DECLINE_BUTTON_TEXT' | translate }}
    </button>
</div>
