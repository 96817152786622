import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { STORAGE_KEY } from 'src/app/core/constants/enum.constants';
import { isMobileDevice } from 'src/app/core/utils';
import { ReferralService } from 'src/app/profile-new/referral/referral.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReferralUrlResolver implements OnDestroy {
    subscription: Subscription;
    dynamicLinkReferral = ['qdqlp8t6', 'pankajjain', '7iy4opg'];
    constructor(@Inject(PLATFORM_ID) private platformId: Object, private referralService: ReferralService) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (state.url.includes('share') && isPlatformBrowser(this.platformId)) {
            const url = state.url.split('/');
            const code = url[url.length - 1];
            this.subscription = this.referralService.getRedirectURLforCustomReferral(code).subscribe({
                next: res => {
                    if (isMobileDevice() && this.dynamicLinkReferral.includes(code)) {
                        window.open(environment.DEEP_LINK_APP + '?module=referral&code=' + code, '_self');
                    } else if (res.dynamic_link) {
                        localStorage.setItem('refCodeEdit', 'true');
                        window.open(res.dynamic_link, '_self');
                    } else {
                        window.open(
                            `${environment.IXFI_EXCHANGE}auth/signup?${STORAGE_KEY.REFERRAL_CODE}=${code}`,
                            '_self'
                        );
                    }
                    return of(true);
                },
                error: err => {
                    return of(true);
                },
                complete: () => {
                    return of(true);
                }
            });
        }
        return of(true);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
