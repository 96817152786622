import { Injectable } from '@angular/core';
import { ApiService } from '../../core';

@Injectable()
export class SupportService {
    constructor(private api: ApiService) {}

    getAllPostWithCategories() {
        return this.api.get('support-portal/get-post-with-category');
    }

    getPostByCategory(slug: string) {
        return this.api.post(`support-portal/get-category-wise-post/${slug}`);
    }

    getPostDetails(post_slug: string) {
        return this.api.post(`support-portal/post-details/${post_slug}`);
    }
}
