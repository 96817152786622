import { Inject, Injectable, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IxfiThemeService } from 'header';

import { UserService } from './user.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { TermsAndConditionsModalComponent } from 'src/app/standalone/components/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';
import { APP_CONSTANTS, STORAGE_KEY } from '../constants/enum.constants';

@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService implements OnDestroy, OnInit {
    modalRef!: NgbModalRef;
    subscriptions: Subscription[] = [];
    isWebView!: boolean;
    modalOpen: boolean;
    constructor(
        private userService: UserService,
        private ngbModal: NgbModal,
        private router: Router,
        private authService: AuthenticationService,
        private theme: IxfiThemeService,
        private location: Location,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        const auth$ = this.authService.loginStatus.subscribe(status => {
            if (!status) {
                this.modalRef?.dismiss(-1);
            }
        });
        this.subscriptions.push(auth$);
    }
    ngOnInit(): void {
        this.isWebView = JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
    }

    openTermsAndConditions() {
        if (this.authService.isLoggedIn() && isPlatformBrowser(this.platformId) && !this.isWebView && !this.modalOpen) {
            this.userService.getUserBasicData().then((user: any) => {
                if (Object.keys(user).length && !user.term_conditions) {
                    this.showTncModal();
                }
            });
        }
    }
    showTncModal() {
        if (this.authService.isLoggedIn() && isPlatformBrowser(this.platformId)) {
            const tnc$ = this.userService.getTncContent().subscribe(res => {
                this.modalOpen = true;
                this.modalRef = this.ngbModal.open(TermsAndConditionsModalComponent, {
                    scrollable: true,
                    size: 'xl',
                    centered: true,
                    backdrop: 'static',
                    keyboard: false,
                    modalDialogClass:
                        'terms-modal ixfi-modal ' + (this.theme.isDarkModeEnabled ? 'terms-dark-mode' : '')
                });
                this.modalRef.componentInstance.description = res?.description || '';
                this.modalRef.componentInstance.title = res?.title || '';
                this.modalRef.dismissed.subscribe((reason: any) => {
                    const reasonString = typeof reason === 'string' ? reason : String(reason);
                    if (reasonString.trim() === APP_CONSTANTS.ACCEPTED) {
                        this.userService.agreeToTnC().subscribe(() => {
                            if (this.location.path() === '/home/welcome') {
                                window.location.reload();
                            }
                        });
                    } else if (reasonString.trim() === APP_CONSTANTS.DECLINED) {
                        this.authService.clearAuthData(false, true);
                    }
                    this.modalOpen = false;
                });
            });
            this.subscriptions.push(tnc$);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
