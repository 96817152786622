import { ChangeDetectorRef, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { TIMER_DEFAULTS } from '../constants/enum.constants';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from 'src/app/shared/services/storage.service';

@Injectable()
export class TimerService {
    interval = {};

    private storageService = inject(StorageService);
    private platformId = inject(PLATFORM_ID);

    checkTimer(timer, key, cdr?: ChangeDetectorRef) {
        let expireTime = JSON.parse(this.storageService.getItem(key));
        timer.timeLeft = this.getRemainingTime(expireTime);
        if (timer.timeLeft < TIMER_DEFAULTS.WAITING_TIME) {
            timer.isDisabled = true;
            if (timer.timeLeft > 59) {
                timer.showTime = `01:` + (timer.timeLeft - 60 < 10 ? '0' + (timer.timeLeft - 60) : timer.timeLeft - 60);
            } else {
                timer.showTime = '00:' + (timer.timeLeft < 10 ? '0' + timer.timeLeft : timer.timeLeft);
            }
            this.setInterVal(timer, key, cdr);
        } else {
            timer.isDisabled = false;
        }
    }
    public startTimer(timer, key, path = '/', cdr?: ChangeDetectorRef) {
        /** Time Interval */
        if (timer.timeLeft !== TIMER_DEFAULTS.WAITING_TIME) {
            return;
        }
        timer.isDisabled = true;
        timer.reSend = false;
        let date = new Date();
        date.setTime(date.getTime() + TIMER_DEFAULTS.WAITING_TIME * 1000); // 1 minute
        this.storageService.setItem(key, JSON.stringify(date), date, path);
        this.setInterVal(timer, key, cdr);
        if (cdr) cdr.detectChanges();
    }

    public timerDetails(timer, key, cdr?: ChangeDetectorRef) {
        if (this.storageService.check(key)) {
            this.checkTimer(timer, key, cdr);
        } else {
            timer.isDisabled = false;
            timer.reSend = false;
            timer.timeLeft = TIMER_DEFAULTS.WAITING_TIME;
            timer.showTime = TIMER_DEFAULTS.SHOW_TIME;
        }
        if (cdr) cdr.markForCheck();
    }

    unfreezeInput(el) {
        const value = el.target.value;
        // setting input value again makes it editable
        el.target.value = value;
    }

    private setInterVal(timer, key, cdr?: ChangeDetectorRef) {
        if (isPlatformBrowser(this.platformId)) {
            this.setTimerInterval(timer, key, cdr);
        }
    }

    private setTimerInterval(timer, key, cdr?: ChangeDetectorRef) {
        this.interval[key] = setInterval(() => {
            if (timer.timeLeft > 1) {
                timer.timeLeft--;
                if (timer.timeLeft > 59) {
                    timer.showTime =
                        `01:` + (timer.timeLeft - 60 < 10 ? '0' + (timer.timeLeft - 60) : timer.timeLeft - 60);
                } else {
                    timer.showTime = '00:' + (timer.timeLeft < 10 ? '0' + timer.timeLeft : timer.timeLeft);
                }
            } else {
                clearInterval(this.interval[key]);
                timer.timeLeft = TIMER_DEFAULTS.WAITING_TIME;
                timer.showTime = TIMER_DEFAULTS.SHOW_TIME;
                timer.isDisabled = false;
                timer.reSend = true;
            }
            if (cdr) cdr.markForCheck();
        }, 1000);
    }

    getRemainingTime(expireTime: string): number {
        let expiryTime = new Date(expireTime);
        let currentTime = new Date();
        if (1 + currentTime.getMinutes() < expiryTime.getMinutes()) {
            return 60 + (expiryTime.getSeconds() + (60 - currentTime.getSeconds()));
        } else {
            return 60 - (currentTime.getSeconds() - expiryTime.getSeconds());
        }
    }

    clearInterval() {
        for (let key in this.interval) {
            if (this.interval.hasOwnProperty(key)) clearInterval(this.interval[key]);
        }
    }
}
